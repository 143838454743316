
import * as ipxRuntime$2MWM6T4WSH from '/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/node_modules/@nuxt/image-edge/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$2MWM6T4WSH, defaults: {"name":"ipx","provider":"/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/node_modules/@nuxt/image-edge/dist/runtime/providers/ipx"} }
}
        